import React from 'react'

import Footer from './Footer'
import profile from '../assets/images/profile3.jpg'

class Header extends React.Component {
  render() {
    return (
      <header id="header">
        <div className="inner">
          <span className="image avatar">
            <img src={profile} alt="Luciano Mariani" />
          </span>
          <h1>
            <strong>Luciano Mariani</strong>
            <br />
            <span>Senior Software Engineer</span>
            <br />
            <span>from Buenos Aires, Argentina.</span>
          </h1>
        </div>
        <Footer />
      </header>
    )
  }
}

export default Header
